/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    'dsaince1swf2jNhF6jGS7V.png': { uri: '/images/dsaince1swf2jNhF6jGS7V.png' },
'sPtoCgYK2DHstmktZoqWY7.png': { uri: '/images/sPtoCgYK2DHstmktZoqWY7.png' },
'1JUix7vMN7Fyu6YkRKChsj.png': { uri: '/images/1JUix7vMN7Fyu6YkRKChsj.png' },
'9uqFimEqNNuhrVwmsLwFAa.png': { uri: '/images/9uqFimEqNNuhrVwmsLwFAa.png' },
'eQK6xHQfJLxhQfFk4999er.png': { uri: '/images/eQK6xHQfJLxhQfFk4999er.png' },
'nvQ5s27uwVA3H1MmssUynM.png': { uri: '/images/nvQ5s27uwVA3H1MmssUynM.png' },
'eykVkas1FsKXU3PrjtTdt2.png': { uri: '/images/eykVkas1FsKXU3PrjtTdt2.png' },
'92txry3EhvuVxHH2zWcJSr.png': { uri: '/images/92txry3EhvuVxHH2zWcJSr.png' },
'6psye9gJP6Q118uEvYVX4j.png': { uri: '/images/6psye9gJP6Q118uEvYVX4j.png' },
'1thZthwv3TvUskwxodwgzQ.png': { uri: '/images/1thZthwv3TvUskwxodwgzQ.png' },
'2CPnCfydiji124Q9BRnk39.png': { uri: '/images/2CPnCfydiji124Q9BRnk39.png' },
'isMkQfnVcjXV2bLrgpJKC4.png': { uri: '/images/isMkQfnVcjXV2bLrgpJKC4.png' },
'4bYqg6KofgpcfiWPxtK5rP.png': { uri: '/images/4bYqg6KofgpcfiWPxtK5rP.png' },
'kMusBQLGE21gXHowNqGDxB.png': { uri: '/images/kMusBQLGE21gXHowNqGDxB.png' },
'udohYzdS5QFT5xtpfQgsix.png': { uri: '/images/udohYzdS5QFT5xtpfQgsix.png' },
'3LbDmNZ8yKosF6sA97iPcw.webp': { uri: '/images/3LbDmNZ8yKosF6sA97iPcw.webp' },
'hmh9avuez8g59fypXxqMBX.png': { uri: '/images/hmh9avuez8g59fypXxqMBX.png' },
'1YBcsPNeJrdZbiSqnv74RD.png': { uri: '/images/1YBcsPNeJrdZbiSqnv74RD.png' },
'n3AveNs4DZdah1SCxLpVxM.png': { uri: '/images/n3AveNs4DZdah1SCxLpVxM.png' },
'tjmcbYbRbeAfGiR2gij2q8.png': { uri: '/images/tjmcbYbRbeAfGiR2gij2q8.png' },
'sCGEBbMYDdwpN99UhHCv5n.png': { uri: '/images/sCGEBbMYDdwpN99UhHCv5n.png' },
'kn7TS5FXB7iGyGNAESKWaW.png': { uri: '/images/kn7TS5FXB7iGyGNAESKWaW.png' },
'fdTbc8qCe98NhRGzTJGUco.png': { uri: '/images/fdTbc8qCe98NhRGzTJGUco.png' },
'1PnTnt5oSPNXD63RivCnnq.png': { uri: '/images/1PnTnt5oSPNXD63RivCnnq.png' },
'kX2GJXYv5p3xENLhn6v7Cn.png': { uri: '/images/kX2GJXYv5p3xENLhn6v7Cn.png' },
'3y6Y1j2xQMPBSPiuYGtsy8.png': { uri: '/images/3y6Y1j2xQMPBSPiuYGtsy8.png' },
'9CoKLcCx2xuaGVDMBF8hXc.png': { uri: '/images/9CoKLcCx2xuaGVDMBF8hXc.png' },
'aLVG7gnZwwEXfpg5o3pq7A.png': { uri: '/images/aLVG7gnZwwEXfpg5o3pq7A.png' },
'ukHz3JWQveLkNZTYWHiEe4.png': { uri: '/images/ukHz3JWQveLkNZTYWHiEe4.png' },
'r6MtB51H4KfTVG37dPXgxk.png': { uri: '/images/r6MtB51H4KfTVG37dPXgxk.png' },
'unX69xQKM3qzztrimwkAMU.png': { uri: '/images/unX69xQKM3qzztrimwkAMU.png' },
'dvZJREzQMD1sZaUTGfVXtU.png': { uri: '/images/dvZJREzQMD1sZaUTGfVXtU.png' },
'oUeJVdoQJHSxzDmzJkwor1.png': { uri: '/images/oUeJVdoQJHSxzDmzJkwor1.png' },
'rSLFwKLe8dRiGiTcYDUQtm.png': { uri: '/images/rSLFwKLe8dRiGiTcYDUQtm.png' },
'8gpg8LPMT5DUAoZco7AvDQ.png': { uri: '/images/8gpg8LPMT5DUAoZco7AvDQ.png' },
'oT11j1At3JrYCE7LApJVwU.png': { uri: '/images/oT11j1At3JrYCE7LApJVwU.png' },
'pRVbS8vJ91ARMQXL9rYLkZ.png': { uri: '/images/pRVbS8vJ91ARMQXL9rYLkZ.png' },
'mj7mxs6ubZtTxJUdyZBfqT.png': { uri: '/images/mj7mxs6ubZtTxJUdyZBfqT.png' }
}

export default imageStaticSourcesByFileName
